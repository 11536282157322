import classNames from 'classnames'
import { Icon, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { Typography } from 'components/DataDisplay/Typography/Typography'
import { Link } from 'components/Link/Link'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'hooks/useTranslation'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { Routes } from 'services/router/Routes'
import { setLocaleCookie } from 'utils/cookieSetter'

import { HeaderLink } from './HeaderLink'
import styles from './HeaderMenuMobile.module.scss'

type HeaderMenuMobileProps = {
  open: boolean
  userName: string | null
  onLinkClick?: () => void
}

const transition = {
  type: 'spring',
  stiffness: 800,
  damping: 35,
}

export const HeaderMenuMobile: FC<HeaderMenuMobileProps> = ({ open, userName, onLinkClick }) => {
  const { t } = useTranslation()
  const { locale, pathname } = useRouter()

  const menuLinks = [
    {
      icon: IconName.HOME5,
      title: t('header:menu.my-house'),
      route: Routes.myAccount(),
      useSSRRouting: true,
      disabled: false,
    },
    /*
    {
      icon: IconName.ACCOUNT_CIRCLE,
      title: t('header:menu.my-data'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    {
      icon: IconName.FILE_LIST,
      title: t('header:menu.my-contracts'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    {
      icon: IconName.HEART,
      title: t('header:menu.my-favorites'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    {
      icon: IconName.TOOLS,
      title: t('header:menu.maintenance'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    {
      icon: IconName.EQUALIZER,
      title: t('header:menu.settings'),
      route: Routes.userDashboard(),
      useSSRRouting: true,
      disabled: true,
    },
    */
    {
      icon: IconName.LOGOUT,
      title: t('header:menu.logout'),
      route: Routes.logout(),
      useSSRRouting: false,
      disabled: false,
    },
  ]

  const generalLinks = [
    { route: Routes.howItWorks(), title: t('header:how-it-works') },
    { route: Routes.properties({}), title: t('header:properties') },
    { route: Routes.reviews(), title: t('header:testimonies') },
    { route: Routes.about(), title: t('header:about-us') },
    { route: Routes.blog(), title: t('header:blog'), external: true },
  ]

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classNames(styles.container)}
          transition={transition}
        >
          {userName && (
            <ul className={styles.userDashboardLinks}>
              <li className={styles.helloUser}>{t('header:hello-customer', { name: userName })}</li>
              {menuLinks.map(({ icon, title, route, disabled, useSSRRouting }) => (
                <HeaderLink
                  key={title}
                  path={route}
                  title={title}
                  iconName={icon}
                  useSSRRouting={useSSRRouting}
                  disabled={disabled}
                  className={styles.userDashboardLink}
                  onClick={onLinkClick}
                />
              ))}
            </ul>
          )}
          <ul className={styles.userStaticLinks}>
            {generalLinks.map(({ route, title, external }) => (
              <li key={title}>
                <Link route={route} className={styles.generalLink} onClick={onLinkClick} newTarget={external}>
                  <Typography>{title}</Typography>
                </Link>
              </li>
            ))}
            {!userName && (
              <HeaderLink
                path={Routes.userLogin()}
                title={t('header:login')}
                iconName={IconName.USER}
                className={styles.userDashboardLink}
                onClick={onLinkClick}
              />
            )}

            <div className={styles.localeWrapper}>
              <button className={styles.localeButton} type="button">
                <Icon size={24} name={IconName.GLOBAL} className={styles.icon} /> <Typography className={styles.text}>{locale}</Typography>
                <Icon size={24} name={IconName.DOWN_ARROW} type={IconType.FILL} className={`${styles.icon} ${styles.iconToggle}`} />
              </button>
              <div className={styles.localeOptionsContainer}>
                <Link
                  route={pathname}
                  onClick={() => setLocaleCookie('es')}
                  locale="es"
                  className={styles.localeOption + (locale === 'es' ? ` ${styles.localeOptionSelected}` : '')}
                >
                  {t('header:language.es')}
                </Link>
                <Link
                  route={pathname}
                  onClick={() => setLocaleCookie('en')}
                  locale="en"
                  className={styles.localeOption + (locale === 'en' ? ` ${styles.localeOptionSelected}` : '')}
                >
                  {t('header:language.en')}
                </Link>
              </div>
            </div>
          </ul>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
